import * as React from 'react'

const AlgoliaSVG = (props) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.16 0h17.68C22.58 0 24 1.412 24 3.16v17.68c0 1.74-1.413 3.16-3.16 3.16H3.16A3.161 3.161 0 0 1 0 20.84V3.153A3.155 3.155 0 0 1 3.16 0z"
			fill="#5468FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.38 6.314c-3.752 0-6.794 3.03-6.794 6.77 0 3.742 3.042 6.764 6.793 6.764 3.75 0 6.793-3.03 6.793-6.77 0-3.742-3.035-6.764-6.793-6.764zm0 11.536c-2.642 0-4.788-2.136-4.788-4.766S9.738 8.32 12.38 8.32c2.642 0 4.787 2.136 4.787 4.765 0 2.63-2.138 4.766-4.787 4.766zm0-8.557v3.552c0 .101.108.174.203.123l3.167-1.634c.073-.037.095-.124.059-.196a3.943 3.943 0 0 0-3.284-1.983c-.073 0-.146.058-.146.138zM7.942 6.685l-.416-.414a1.043 1.043 0 0 0-1.474 0l-.497.494a1.031 1.031 0 0 0 0 1.467l.41.407c.065.065.16.05.218-.015A7.63 7.63 0 0 1 7.92 6.895c.073-.043.08-.145.022-.21zm6.662-1.075v-.821A1.04 1.04 0 0 0 13.56 3.75h-2.43a1.04 1.04 0 0 0-1.043 1.039v.842c0 .095.087.16.182.138a7.636 7.636 0 0 1 2.116-.297c.694 0 1.38.094 2.044.276a.141.141 0 0 0 .175-.138z"
			fill="#fff"
		/>
	</svg>
)

export default AlgoliaSVG
